import React, { useCallback, useState } from 'react';
import { useI18next } from "gatsby-plugin-react-i18next";
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs'

import './card.component.scss';
import { Button } from './button.component';
import { getBarcode } from '../utils/format.barcode';
import { setRegistration } from '../states/registrations.slice';
import { ProgressBar } from './progress-bar.component';
import { getFullNameKanji } from '../utils/patient-info.format';
import DownloadButton from '../assets/download-button.svg';
import Loading from '../assets/loading-spinner.svg';
import { downloadResultPdf } from '../services/users.service';

const IN_LAB_STATUSES = ['PATIENT_COMPLETED', 'RECEIVED_IN_LAB', 'PENDING_FOLLOW_UP', 'IN_TESTING', 'COMPLETED'];
const checkIsInLab = (status) => IN_LAB_STATUSES.indexOf(status) !== -1;

const getProgress = (status) => {
    if (status === 'IN_PROGRESS') return 20;
    if (status === 'PATIENT_COMPLETED') return 40;
    if (status === 'SHIPPING') return 60;
    if (status === 'RECEIVED_IN_LAB' || status === 'IN_TESTING') return 80;
    return 100;
}

export const Card = ({ registration, className, onRemove, ...rest }) => {
    const { t, navigate } = useI18next();
    const dispatch = useDispatch();
    const [ isDownloading, setIsDownloading ] = useState(false);

    const { patient, ...reg } = registration;

    const onResume = useCallback(() => {
        dispatch(setRegistration(reg));
        navigate('/register/symptoms-and-history');
    }, [reg, dispatch, navigate]);

    const handleDownload = registrationId => {
        setIsDownloading(true);
        downloadResultPdf(registrationId).then(() => {
            setIsDownloading(false);
        });
    };

    const isInLab = (status) => checkIsInLab(status);

    const showResult = registration.result && registration.result !== 'RETEST';
    return <div className={`hap-Card ${className} ${isInLab(registration.status) ? 'hap-Card--in-lab': ''}`} {...rest}>
        <div className="row spance-between">
            <span className="name">{getFullNameKanji(patient)}</span>
            {showResult && (
                <button type="button" onClick={() => handleDownload(registration.id)} className="download-btn">
                    { isDownloading ? <Loading /> : <DownloadButton /> }
                </button>
            )}
        </div>
        <div className="row">
            <div className="half">
                <label>{t('Test ID')}:</label>
                {reg.barcode && (getBarcode(reg.barcode)) || t('N/A')}
            </div>
            { !registration.result && (
            <div className="half">
                <label>{t('Expect results on')}:</label>
                {t('N/A')}
            </div>
            )}
            { registration.result && (
            <div className="result-display">
                <div className="half">
                    <label>{t('Results')}:</label>
                    {t(registration.result)}
                </div>
                <div className="half">
                    <label>{t('Finished date')}:</label>
                    {dayjs(registration?.testingDate).format('YYYY年MM月DD日')}
                </div>
            </div>
            )}
        </div>
        <ProgressBar progress={getProgress(registration.status)} breakpoint={50} />
        {!isInLab(registration.status) && (
            <div className="row btn-row">
                <Button buttonType="default-orange" size="small" onClick={() => onRemove(registration.id)}>{t('Delete')}</Button>
                <Button buttonType="play" size="small" onClick={onResume}>{t('Resume')}</Button>
            </div>
        )}
    </div>;
};