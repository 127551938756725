import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRegistrationsAsync } from "../states/registrations.slice";

export const useRegistrations = () => {
    const dispatch = useDispatch();

    const { registrations } = useSelector(s => s.registrations);

    useEffect(() => {
        dispatch(fetchRegistrationsAsync());
    }, []);

    return registrations;
};