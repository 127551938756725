import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'gatsby';
import { useDispatch } from "react-redux";
import { StringParam, useQueryParam } from 'use-query-params';

import { Card } from '../../components/card.component';
import { List } from '../../components/list.component';
import { getBarcode } from '../../utils/format.barcode';
import { fetchRegistrationsAsync } from "../../states/registrations.slice";

import { ListItem } from '../../components/list-item.component';
import { MenuLayout } from '../../components/menu-layout.component';
import { ConfirmModal } from '../../components/confirm-modal.component';
import { removeRegistration } from '../../services/registrations.service';
import { useRegistrations } from '../../hooks/use-registrations.hook';
import ProgressIcon from '../../assets/progress-icon.svg';
import { MessageBox } from '../../components/message-box.component';
import { getFullNameKanji } from '../../utils/patient-info.format';

const getDate = (timestamp) => dayjs(timestamp).format('MM-DD-YYYY');

const today = dayjs();

export default function Home() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const registrations = useRegistrations();
    const [showModal, setShowModal] = useState(false);
    const [selectedRegistration, setSelectedRegistration] = useState({});
    const [ updatedEmail ] = useQueryParam('updatedEmail', StringParam);
    const [ showMessage, setShowMessage ] = useState(false);

    useEffect(() => {
        if (updatedEmail && updatedEmail === 'success') {
            setShowMessage(true);
        }
    }, [ updatedEmail ]);

    // test is over 30 days
    const isPastTest = (test) => {
        return test.status === 'COMPLETED' && today.diff(dayjs(test.testingDate), 'day') >= 2;
    };
    const handleConfirmDelete = () => {
        removeRegistration(selectedRegistration.id)
        .then(res => { dispatch(fetchRegistrationsAsync()); })
        .catch(error => console.error('remove registration', error));
    }

    const pastTests = registrations.filter(isPastTest);
    
    return <MenuLayout className="dashboard">
        <div className="section section--dashboard animateIn">
            {showMessage && <MessageBox message={t('Your email has been updated successfully.')} onClose={() => setShowMessage(false)} />}
            {showModal && <ConfirmModal onCloseModal={() => setShowModal(false)} onConfirm={handleConfirmDelete}/>}
            <h1 className="dashboard-title t-h2 t-grey9"><ProgressIcon /> {t('Dashboard')}</h1>
            {registrations.length === 0 ? (
                <>
                    <p className="t-grey9 t-sans">{t('No testing registered yet, please register a new test kit to get start')}</p>
                    <Link className="hap-button product-ads__button" hrefLang="ja" to="/register">{t('Register test kit')}</Link>
                </>
            ) : (
                <>
                    <h4 className="t-subheading1 t-grey9 t-sans">{t('Testing in progress')}</h4>
                    <div className="cards-container">       
                        {registrations.filter(r => !isPastTest(r)).map((registration, i) => <Card key={i} registration={registration} onRemove={() => {
                            setShowModal(true);
                            setSelectedRegistration(registration);
                        }} />)}
                    </div>
                </>
            )}
        </div>
        {pastTests.length > 0 && (
            <List title={t('Past tests')}>
                {pastTests.map(registration =>(
                    <ListItem key={registration.id} registrationId={registration.id} head={`${getFullNameKanji(registration?.patient)}`}> {getDate(registration.modifiedAt)} | {t(registration.result)} (#{getBarcode(registration.barcode)})</ListItem>
                ))}
            </List>
        )}
    </MenuLayout>;
}
