import React, { useState } from 'react';

import './list-item.component.scss';
import ExpandButton from '../assets/expand-button.svg';
import DownloadButton from '../assets/download-button.svg';
import Loading from '../assets/loading-spinner.svg';
import { downloadResultPdf } from '../services/users.service';

export const ListItem = ({ children, className, head, registrationId, ...rest }) => {

    const [expanded, setExpanded] = useState(false);
    const [ isDownloading, setIsDownloading ] = useState(false);

    const onExpandToggle = () => {
        setExpanded(!expanded);
    };

    const handleDownload = () => {
        setIsDownloading(true);
        downloadResultPdf(registrationId).then(() => {
            setIsDownloading(false);
            setExpanded(false);
        });
    };

    return <div className={`hap-list-item ${className || ''} ${expanded ? 'expanded' : ''}`} {...rest}>
        <div className="main">
            <div className="head">{head}</div>
            <div className="description">{children}</div>
        </div>
        <div className="right">
            <ExpandButton className="expand-button" onClick={onExpandToggle} />
        </div>
        <div className="expanded-menu expanded-menu-select">
            <button type="button" onClick={handleDownload} className="block-btn download-btn">
                { isDownloading ? <Loading /> : <DownloadButton /> }
            </button>
        </div>
    </div>;
};