import React from 'react';

import './list.component.scss';

export const List = ({ children, title, className, ...rest }) => {
    return <div className={"hap-list "+(className||'')} {...rest}>
        <div className="title-section t-subheading1 t-sans">
            { title }
        </div>
        <div className="items">
            { children }
        </div>
    </div>;
};