import React from 'react';
import disableScroll from 'disable-scroll';
import { useI18next } from 'gatsby-plugin-react-i18next';

import './confirm-modal.component.scss';
import { Button } from './button.component';
import CrossSVG from '../assets/svgs/close.svg';

export const ConfirmModal = ({ onCloseModal, onConfirm }) => {
    const { t } = useI18next();
    return (
        <div className="confirm-modal" onClick={onCloseModal}>
            <div className="modal-container">
                <CrossSVG />
                <div className="modal">
                    <p className="t-sans t-bold t-grey9">{t('Delete registration')}</p>
                    <p className="t-sans t-grey7">{t('Are you sure to delete?')}</p>
                    <div className="modal-buttons">
                        <Button buttonType="default" size="small" onClick={onCloseModal}>{t('No')}</Button>
                        <Button buttonType="default-orange" size="small" onClick={onConfirm}>{t('Yes')}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
