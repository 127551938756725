import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './progress-bar.component.scss';

const status_label = {
    20: 'IN_PROGRESS',
    40: 'PATIENT_COMPLETED',
    60: 'SHIPPING',
    80: 'RECEIVED_IN_LAB'
};

export const ProgressBar = ({ progress, breakpoint, ...rest }) => {

    const textRef = useRef();
    const textContainerRef = useRef();
    const [ textOffset, setTextOffset ] = useState(0);
    const { t } = useTranslation();

    if (!breakpoint) {
        breakpoint = 0;
    }

    useLayoutEffect(() => {
        const offset = textRef.current.clientWidth / 2;
        const progressRatio = progress / 100;
        const textPosition = textContainerRef.current.clientWidth * progressRatio
        setTextOffset(textPosition - offset);
    }, [ textRef, textContainerRef ])
    
    return <div className={`hap-ProgressBar`} {...rest}>
        <div className={`bar ${progress < breakpoint ? 'yellow' : ''}`}>
            <div className="progress" style={{ width: `${progress}%`}}></div>
            <div className="dot" style={{ left: `${progress}%`}}></div>
            <div className="progress-label" style={{ left: `${progress}%`}}>{t(status_label[progress])}</div>
        </div>
        <div className="text-container" ref={textContainerRef}>
            <div className="text" ref={textRef}>
            </div>
        </div>
        
    </div>;
};