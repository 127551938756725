import React from 'react';
import './message-box.component.scss';

export const MessageBox = ({ message, onClose }) => {
    return (
        <div className="message-box">
            <button className="message-box__close" type="button" onClick={onClose}>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.92831 1.65542C3.01441 0.741512 1.7639 0.509074 1.13649 1.13649C0.509074 1.7639 0.741512 3.01441 1.65542 3.92831L10.7272 13.0001L1.65545 22.0718C0.741543 22.9857 0.509105 24.2362 1.13652 24.8636C1.76393 25.491 3.01444 25.2586 3.92835 24.3447L13.0001 15.273L22.0718 24.3447C22.9857 25.2586 24.2362 25.491 24.8636 24.8636C25.491 24.2362 25.2586 22.9857 24.3447 22.0718L15.273 13.0001L24.3447 3.92832C25.2586 3.01441 25.491 1.7639 24.8636 1.13649C24.2362 0.509075 22.9857 0.741513 22.0718 1.65542L13.0001 10.7272L3.92831 1.65542Z" fill="white"/>
                </svg>
            </button>
            <p className="message-box__body t-sans t-bold t-white">{message}</p>
        </div>
    );
}